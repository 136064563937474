export const authenticateUser = (state = null, action) => {
    if (action.type === "authenticateUser") {
      return state  =  action.payload;
    }
   else {
      return state;
    }
  };
  
  export const liveToken = (state = null, action) => {
    if (action.type === "liveToken") {
      return state  =  action.payload;
    }
   else {
      return state;
    }
  };
  