export const addAuthenticateUser = (user) => {
  return (dispatch) => {
    dispatch({
      type: "authenticateUser",
      payload: user,
    });
  };
};

export const addLiveToken = (token) => {
  return (dispatch) => {
    dispatch({
      type: "liveToken",
      payload: token,
    });
  };
};